import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbToastrModule, NbDialogModule, NbIconModule, NbButtonModule, NbSearchModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NotFoundComponent } from './not-found/not-found.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';

export function languageLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        NbLayoutModule,
        AppRoutingModule,
        NbEvaIconsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NbToastrModule.forRoot(),
        NbDialogModule.forRoot(),
        NbThemeModule.forRoot({ name: 'default' }),

        NbIconModule,
        NbButtonModule,
        NbSearchModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (languageLoader),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private readonly translateSvc: TranslateService,
    ) {
        this.translateSvc.addLangs(['it']);
        this.translateSvc.setDefaultLang('it');
        this.translateSvc.use('it');
    }
}
