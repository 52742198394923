<nb-layout>

    <nb-layout-header fixed *ngIf="appHeaderConfig?.show">
        <div id="headerTitle" *ngIf="!!appHeaderConfig.title" [innerHTML]="appHeaderConfig.title">
        </div>
        <div id="headerMiddle">

        </div>
        <div id="headerActions" class="d-flex justify-content-end">
            <nb-search tag="global" type="modal-move" [style.visibility]="'hidden'"
                       [hint]="'demo.nearby.press_enter_to_search' | translate"
                       [placeholder]="'demo.nearby.type_city_name' | translate"></nb-search>
            <button nbButton outline status="primary"
                    *ngIf="appHeaderConfig?.showSearch"
                    (click)="!!appHeaderConfig.onSearchClick ? appHeaderConfig.onSearchClick() : null">
                <nb-icon [icon]="appHeaderConfig?.searchIconName" pack="eva">
                </nb-icon>
                {{appHeaderConfig?.searchButtonText | translate}}
            </button>
        </div>
    </nb-layout-header>

    <nb-layout-column [style.background]="appLayoutColConfig?.background">
        <router-outlet></router-outlet>
    </nb-layout-column>

    <nb-layout-footer fixed *ngIf="appFooterConfig?.show">
        <span [innerHTML]="appFooterConfig?.title"></span>
    </nb-layout-footer>
</nb-layout>