import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IBarConfig {
    title: string;
    show?: boolean;
}

export interface IAppHeaderConfig extends IBarConfig {
    showSearch?: boolean;
    searchIconName?: string;
    searchButtonText?: string;
    onSearchClick?: () => void;
}

export interface IAppFooterConfig extends IBarConfig { };

export interface IAppLayoutColConfig {
    background?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {

    private readonly appHeaderConfigInternal$ = new BehaviorSubject<IAppHeaderConfig>({
        title: null,
        show: false,
        showSearch: false,
        onSearchClick: null,
    });

    private readonly appFooterConfigInternal$ = new BehaviorSubject<IAppFooterConfig>({
        title: null,
        show: false,
    });

    private readonly appLayoutColConfigInternal$ = new BehaviorSubject<IAppLayoutColConfig>({
        background: '#fff',
    });

    constructor(
        private readonly http: HttpClient,
    ) { }

    set appHeaderConfig(v: IAppHeaderConfig) { this.appHeaderConfigInternal$.next(v); }
    get appHeaderConfig$(): Observable<IAppHeaderConfig> { return this.appHeaderConfigInternal$.asObservable(); }

    set appLayoutColConfig(v: IAppLayoutColConfig) { this.appLayoutColConfigInternal$.next(v); }
    get appLayoutColConfig$(): Observable<IAppLayoutColConfig> { return this.appLayoutColConfigInternal$.asObservable(); }

    set appFooterConfig(v: IAppFooterConfig) { this.appFooterConfigInternal$.next(v); }
    get appFooterConfig$(): Observable<IAppFooterConfig> { return this.appFooterConfigInternal$.asObservable(); }

}