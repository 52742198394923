// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseApi: 'https://cloud-dev.t20.topevo.eu/rest-api/v1/',
    googleGeocodeApiKey: 'AIzaSyCvy8_nM65i4n-zemXSmXV2j0A6vr6EN0U',
    t20ApiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiIyNjA1MjJhMTY1MTk3YWI1NDhhN2UzZjhjYzdmOThiMiIsInZlcnNpb24iOjEsImV4cGlyYXRpb24iOi0xLCJpYXQiOjE2MTM3Nzg0OTQsImV4cCI6MTYxMzc3ODQ5NCwiaXNzIjoidG91cmlzbTIwLWlkZW50aXR5In0.v8FNwLj6dMhlOQXqjyo2FXaCuEoFhpZV0xGuVtly9V0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
