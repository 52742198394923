import { ChangeDetectorRef, Component } from '@angular/core';
import { finalize, takeWhile, tap } from 'rxjs/operators';
import { AppConfigService, IAppFooterConfig, IAppHeaderConfig, IAppLayoutColConfig } from './core/services/app-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    private isAlive = true;
    appHeaderConfig: IAppHeaderConfig;
    appFooterConfig: IAppFooterConfig;
    appLayoutColConfig: IAppLayoutColConfig;

    constructor(
        private readonly appConfigSvc: AppConfigService,
        private readonly changeDetector: ChangeDetectorRef,
    ) { }

    ngAfterViewInit(): void {
        this.appConfigSvc.appHeaderConfig$.pipe(
            takeWhile(() => this.isAlive),
            tap(config => {
                this.appHeaderConfig = config;
                this.changeDetector.detectChanges();
            }),
        ).subscribe();

        this.appConfigSvc.appLayoutColConfig$.pipe(
            takeWhile(() => this.isAlive),
            tap(config => {
                this.appLayoutColConfig = config;
                this.changeDetector.detectChanges();
            }),
        ).subscribe();

        this.appConfigSvc.appFooterConfig$.pipe(
            takeWhile(() => this.isAlive),
            tap(config => {
                this.appFooterConfig = config;
                this.changeDetector.detectChanges();
            }),
        ).subscribe();

        this.appConfigSvc.appFooterConfig = {
            show: true,
            title: `Powered by&nbsp;<strong>Top</strong><strong>Evolutions</strong>`,
        };
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }
}
